<template>
    <div v-if="point != null" class="container"> 
        <!-- Data e ora -->
        <p>
            <span class="badge bg-secondary mx-1"><img class="px-2" src="../assets/calendar.png"> {{ positionDate }}</span> 
            <span class="badge bg-secondary mx-1"><img class="px-2" src="../assets/time.png"> {{ positionTime }}</span> 
            <span class="badge bg-secondary mx-1"><img class="px-2" src="../assets/sat.png"> {{ sat }}</span> 
            <span class="badge bg-secondary mx-1"><img class="px-2" src="../assets/bt.png"> {{ bLv }} mV</span> 
            <span class="badge bg-secondary mx-1"><img class="px-2" src="../assets/bt.png"> {{ bPr }} %</span> 
            <span class="badge bg-secondary mx-1"><img class="px-2" style="height: auto; width: 32px" src="../assets/speed.png">
                <span v-if="speed > 0">{{ speed }}</span>
                <span v-if="speed == 0">STOP</span> 
            </span> 
            <street-name :lat="point.lat" :lng="point.lng" :hint="point.street" @on-street-name="on_street_name" />
        </p>
    </div>
</template>

<script>
import StreetName from './StreetName.vue';
export default {
  components: { StreetName },
    props: {
        point:  { required: true, default: null }, 
		gtm: { required: false, default: true }
    },
 
    computed: {
        positionDate: function() {
            var dt = this.get_offset_date();
			return dt.toLocaleDateString('it-IT');
        },

        positionTime: function() {
            var dt = this.get_offset_date();
			return dt.toLocaleTimeString('it-IT');
        },
        sat: function() {
            return this.point.sat;
        },
        speed: function() {
            return this.point.speed;
        },
        bPr: function() {
            if(this.point.bPr && this.point.bPr != null)
                return this.point.bPr;
            else
                return "?";
        },
        bLv: function() {
            if(this.point.bLv && this.point.bLv != null)
                return this.point.bLv;
            else
                return "?";
        }
    }, 
 

    methods: {
        /**
         * Nome della strada
         */
        on_street_name: function(result) { 
            this.$emit('on-street-name', result);
        },

        change_tz: function(date, tzString) {
            return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));  
        },
        
        get_offset_date: function() {
            var dt = new Date( this.point.posDate );  
            
            if( dt.getTimezoneOffset() != this.isDST(dt))
                dt.setTime(dt.getTime() - (1*60*60*1000)); 
            else
                dt.setTime(dt.getTime() - (1*60*60*1000)); 

            return dt; 
        },
        isDST: function(d) {
            let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
            let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
            return Math.max(jan, jul) !== d.getTimezoneOffset();    
        },
    }
}
</script>

<style>
.icon {
    display: inline-block;
    height: 100%; 
}
</style>